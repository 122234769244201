import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { TourForm } from '../forms'
import { Dashboard } from '../layouts'
import axios from '../plugins/axios'

const styles = theme => ({
    //
})

class TourUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Tours',
            key: shortid.generate(),
            name: '',
            images: [],
            duration: '',
            map: '',
            summary: '',
            code: '',
            tour_guide: '',
            tour_type: '',
            pick_up_service: '',
            activity_level: '',
            highlights: [''],
            itinerary: '',
            included: [''],
            not_included: [''],
            end_point: '',
            destination_ids: [],
            destinations: []
        }
    }

    componentDidMount = () => {
        this.fetchData()
        this.fetchDestinations()
    }

    fetchDestinations = async () => {
        try {
            const { data } = await axios.get('/destinations', {
                progress: true,
                params: {
                    rowsPerPage: -1,
                    sortBy: 'name',
                    sortOrder: 'asc'
                }
            })

            this.setState({
                key: shortid.generate(),
                destinations: data,
            })
        } catch (e) {
            //
        }
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/tours/${id}`)

            this.setState({
                key: shortid.generate(),
                name: data.name,
                images: data.images,
                duration: data.duration,
                map: data.map,
                summary: data.summary,
                code: data.code,
                tour_guide: data.tour_guide,
                tour_type: data.tour_type,
                pick_up_service: data.pick_up_service,
                activity_level: data.activity_level,
                highlights: data.highlights,
                itinerary: data.itinerary,
                included: data.included,
                not_included: data.not_included,
                end_point: data.end_point,
                destination_ids: data.destination_ids
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            name,
            images,
            duration,
            map,
            summary,
            code,
            tour_guide,
            tour_type,
            pick_up_service,
            activity_level,
            highlights,
            itinerary,
            included,
            not_included,
            end_point,
            destination_ids,
            destinations,
        } = this.state

        const { match } = this.props
        const { id } = match.params

        return (
            <Dashboard title={title}>
                <TourForm
                    key={key}
                    path={`tours/${id}`}
                    method="put"
                    name={name}
                    images={images}
                    duration={duration}
                    map={map}
                    summary={summary}
                    code={code}
                    tour_guide={tour_guide}
                    tour_type={tour_type}
                    pick_up_service={pick_up_service}
                    activity_level={activity_level}
                    highlights={highlights}
                    itinerary={itinerary}
                    included={included}
                    not_included={not_included}
                    end_point={end_point}
                    destination_ids={destination_ids}
                    destinations={destinations}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(TourUpdate)
