import React, { Component } from 'react'
import { Editor } from '@tinymce/tinymce-react'

export default class BaseEditor extends Component {
    render() {
        const {
            value,
            onChange,
        } = this.props
        const {
            REACT_APP_API_URL,
            REACT_APP_TINY_MCE_API_KEY
        } = process.env

        return (
            <Editor
                initialValue={value}
                onChange={onChange}
                apiKey={REACT_APP_TINY_MCE_API_KEY}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'link', 'lists', 'fullscreen', 'paste', 'image', 'code'
                    ],
                    toolbar: [
                        'undo redo | link | bold | fullscreen',
                        'numlist bullist | alignleft aligncenter alignright alignjustify | image | code',
                        'h1 h2 h3 h4 h5 h6 | fontsizeselect | underline',
                        'blockquote | forecolor | backcolor'
                    ],
                    images_upload_url: `${REACT_APP_API_URL}/api/img/upload/mce`,
                }}
            />
        )
    }
}
