import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { CalendarForm } from '../forms'
import { Dashboard } from '../layouts'
import axios from '../plugins/axios'

const styles = theme => ({
    //
})

class CalendarUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Calendar',
            key: shortid.generate(),
            name: '',
            duration: '',
            departing: null,
            day: null,
            tour_id: '',
        }
    }

    componentDidMount = () => {
        this.fetchData()
        this.fetchTours()
    }

    fetchTours = async () => {
        try {
            const { data } = await axios.get('/tours', {
                progress: true,
                params: {
                    rowsPerPage: -1,
                    sortBy: 'name',
                    sortOrder: 'asc'
                }
            })

            this.setState({
                key: shortid.generate(),
                tours: data,
            })
        } catch (e) {
            //
        }
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/calendar/${id}`)

            this.setState({
                key: shortid.generate(),
                name: data.name,
                duration: data.duration,
                departing: data.departing,
                day: data.day,
                tour_id: data.tour_id
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            name,
            duration,
            departing,
            day,
            tour_id,
            tours,
        } = this.state

        const { match } = this.props
        const { id } = match.params

        return (
            <Dashboard title={title}>
                <CalendarForm
                    key={key}
                    path={`calendar/${id}`}
                    method="put"
                    name={name}
                    duration={duration}
                    departing={departing}
                    day={day}
                    tour_id={tour_id}
                    tours={tours}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(CalendarUpdate)
