import React from 'react'
import {
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom'
import {
    ClientNew,
    Clients,
    ClientUpdate,
    EmployeeNew,
    Employees,
    EmployeeUpdate,
    ForgotPassword,
    Home,
    Login,
    NotFound,
    PasswordReset,
    PostNew,
    Posts,
    PostUpdate,
    Register,
    Requests,
    TipNew,
    Tips,
    TipUpdate,
    AboutsNew,
    Abouts,
    AboutsUpdate,
    UserNew,
    Users,
    UserUpdate,
    TourNew,
    Tours,
    TourUpdate,
    Destinations,
    DestinationNew,
    DestinationUpdate,
    AttractionUpdate,
    AttractionNew,
    Attractions,
    Reviews,
    ReviewNew,
    ReviewUpdate,
    Resumes,
    Calendar,
    CalendarNew,
    CalendarUpdate
} from './pages'
import { 
    SettingForm, 
    RequestForm,
    ResumeForm
} from './forms';

export default () => (
    <Router>
        <Switch>
            <Route
                path="/signin"
                component={Login}
            />
            <Route
                path="/signup"
                component={Register}
            />
            <Route
                path="/forgot/password"
                component={ForgotPassword}
            />
            <Route
                path="/password/reset/:token"
                component={PasswordReset}
            />
            <Route
                path="/"
                component={Home}
                exact
            />
            <Route
                path="/users/new"
                component={UserNew}
            />
            <Route
                path="/users/:id"
                component={UserUpdate}
            />
            <Route
                path="/users"
                component={Users}
            />
            <Route
                path="/tours/new"
                component={TourNew}
            />
            <Route
                path="/tours/:id"
                component={TourUpdate}
            />
            <Route
                path="/tours"
                component={Tours}
            />
            <Route
                path="/calendar/new"
                component={CalendarNew}
            />
            <Route
                path="/calendar/:id"
                component={CalendarUpdate}
            />
            <Route
                path="/calendar"
                component={Calendar}
            />
            <Route
                path="/posts/new"
                component={PostNew}
            />
            <Route
                path="/posts/:id"
                component={PostUpdate}
            />
            <Route
                path="/posts"
                component={Posts}
            />
            <Route
                path="/employees/new"
                component={EmployeeNew}
            />
            <Route
                path="/employees/:id"
                component={EmployeeUpdate}
            />
            <Route
                path="/employees"
                component={Employees}
            />
            <Route
                path="/tips/new"
                component={TipNew}
            />
            <Route
                path="/tips/:id"
                component={TipUpdate}
            />
            <Route
                path="/tips"
                component={Tips}
            />
            <Route
                path="/clients/new"
                component={ClientNew}
            />
            <Route
                path="/clients/:id"
                component={ClientUpdate}
            />
            <Route
                path="/clients"
                component={Clients}
            />
            <Route
                path="/abouts/new"
                component={AboutsNew}
            />
            <Route
                path="/abouts/:id"
                component={AboutsUpdate}
            />
            <Route
                path="/abouts"
                component={Abouts}
            />
            <Route
                path="/destinations/new"
                component={DestinationNew}
            />
            <Route
                path="/destinations/:id"
                component={DestinationUpdate}
            />
            <Route
                path="/destinations"
                component={Destinations}
            />
            <Route 
                path="/attractions/new"
                component={AttractionNew}
            />
            <Route 
                path="/attractions/:id"
                component={AttractionUpdate}
            />
            <Route 
                path="/attractions"
                component={Attractions}
            />
            <Route 
                path="/reviews/new"
                component={ReviewNew}
            />
            <Route 
                path="/reviews/:id"
                component={ReviewUpdate}
            />
            <Route 
                path="/reviews"
                component={Reviews}
            />
            <Route
                path="/settings"
                component={SettingForm}
            />
            <Route
                path="/requests/:id"
                component={RequestForm}
            />
            <Route
                path="/requests"
                component={Requests}
            />
            <Route 
                path="/resumes/:id"
                component={ResumeForm}
            />
            <Route 
                path="/resumes"
                component={Resumes}
            />
            <Route component={NotFound} />
        </Switch>
    </Router>
)
