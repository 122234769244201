import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { ReviewForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class ReviewUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Reviews',
            key: shortid.generate(),
            name: '',
            image: '',
            body: ''
        }
    }

    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/reviews/${id}`)

            this.setState({
                key: shortid.generate(),
                name: data.name,
                image: data.image,
                body: data.body
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            name,
            image,
            body
        } = this.state
        const { match } = this.props
        const { id } = match.params
        return (
            <Dashboard title={title}>
                <ReviewForm
                    key={key}
                    path={`reviews/${id}`}
                    method="put"
                    name={name}
                    image={image}
                    body={body}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(ReviewUpdate)
