import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { DestinationForm } from '../forms'
import { Dashboard } from '../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class DestinationNew extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            title: 'Destinations',
            key: shortid.generate(),
            attractions: []
        }
    }

    componentDidMount = () => {
        this.fetchAttractions()
    }

    fetchAttractions = async () => {
        try {
            const { data } = await axios.get('/attractions', {
                progress: true,
                params: {
                    rowsPerPage: -1,
                    sortBy: 'name',
                    sortOrder: 'asc'
                }
            })

            this.setState({
                key: shortid.generate(),
                attractions: data
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            key,
            title,
            attractions
        } = this.state

        return (
            <Dashboard title={title}>
                <DestinationForm 
                    key={key}
                    attractions={attractions}
                    path="destinations"
                    method="post"
                    message="Амжилттай нэмэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(DestinationNew)
