import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { DestinationForm } from '../forms'
import { Dashboard } from '../layouts'
import axios from '../plugins/axios'

const styles = theme => ({
    //
})

class DestinationUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Destinations',
            key: shortid.generate(),
            name: '',
            images: [],
            description: '',
            attractions: [],
            attraction_ids: []
        }
    }

    componentDidMount = () => {
        this.fetchData()
        this.fetchAttractions()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/destinations/${id}`)

            this.setState({
                key: shortid.generate(),
                name: data.name,
                images: data.images,
                description: data.description,
                attraction_ids: data.attraction_ids
            })
        } catch (e) {
            //
        }
    }

    fetchAttractions = async () => {
        try {
            const { data } = await axios.get('/attractions', {
                progress: true,
                params: {
                    rowsPerPage: -1,
                    sortBy: 'name',
                    sortOrder: 'asc'
                }
            })

            this.setState({
                key: shortid.generate(),
                attractions: data
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            name,
            images,
            description,
            attractions,
            attraction_ids
        } = this.state
        const { match } = this.props
        const { id } = match.params
        return (
            <Dashboard title={title}>
                <DestinationForm
                    key={key}
                    path={`destinations/${id}`}
                    method="put"
                    name={name}
                    images={images}
                    description={description}
                    attractions={attractions}
                    attraction_ids={attraction_ids}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(DestinationUpdate)
