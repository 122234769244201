import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Button,
    Grid,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    CircularProgress
} from '@material-ui/core'
import {
    BaseDatePicker,
    CustomSnackBar
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    marginLeft: {
        margin: '0 10px 0 0'
    },
    marginBottom: {
        margin: '0 0 20px 0'
    },
    marginVerticalSmall: {
        margin: '10px 0 10px 0'
    },
})

class CalendarForm extends Component {

    static defaultProps = {
        name: '',
        duration: '',
        departing: null,
        tour_id: '',
        tours: []
    }

    constructor(props) {
        super(props)

        const {
            name,
            duration,
            departing,
            tour_id,
            tours,
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            errors: {},
            name,
            duration,
            departing,
            tour_id,
            tours
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            errors: {},
            name: '',
            duration: '',
            departing: null,
            tour_id: ''
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true,
            errors: {}
        })

        const {
            path,
            method
        } = this.props

        const {
            name,
            duration,
            departing,
            tour_id
        } = this.state

        try {
            await axios[method](path, {
                name,
                duration,
                departing,
                tour_id
            })

            this.setState({
                isSnackOpen: true
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({ errors })
            }
        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    selectChanged = e => {
        const { value } = e.target

        this.setState({ tour_id: value })
    }

    departingChanged = departing => {
        this.setState({ departing })
    } 

    render() {
        const {
            name,
            duration,
            departing,
            tour_id,
            tours,
            isSnackOpen,
            errors,
            disabled
        } = this.state

        const {
            message
        } = this.props
        return (
            <div>
                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                error={!!errors.name}
                                helperText={errors.name}
                                label="Name"
                                value={name}
                                onChange={this.handleChange('name')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.duration}
                                helperText={errors.duration}
                                label="Duration"
                                value={duration}
                                onChange={this.handleChange('duration')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <Box my={3}>
                                <BaseDatePicker
                                    label="Departing"
                                    value={departing}
                                    format="yyyy-dd-MM"
                                    onChange={this.departingChanged}
                                />
                            </Box>
                            <Box my={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="tour_id-simple">Tours</InputLabel>
                                    <Select
                                        error={!!errors.tour_id}
                                        helperText={errors.tour_id}
                                        value={tour_id}
                                        onChange={this.selectChanged}
                                        input={
                                            <OutlinedInput title="tour_id" id="tour_id-simple" />
                                        }
                                    >
                                        {
                                            tours.map(val =>
                                                <MenuItem
                                                    key={val.id}
                                                    value={val.id}
                                                >
                                                    {val.name}
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box my={3}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={disabled}
                                >
                                    {
                                        disabled ? <CircularProgress size={25} color="primary" /> : "Хадгалах"
                                    }
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <CustomSnackBar
                        message={message}
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(CalendarForm)
