import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Grid,
    Box,
    Typography,
} from '@material-ui/core'
import {
    DropZoneMultiple
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    marginLeft: {
        margin: '0 10px 0 0'
    },
    marginBottom: {
        margin: '0 0 20px 0'
    }
})

class AttractForm extends Component {

    static defaultProps = {
        name: '',
        images: [],
        summary: '',
    }

    constructor(props) {
        super(props)

        const {
            name,
            summary,
            images,
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            errors: {},
            name,
            summary,
            images,
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            errors: {},
            name: '',
            images: [],
            summary: '',
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true,
            errors: {}
        })

        const {
            path,
            method
        } = this.props

        const {
            name,
            summary,
            images,
        } = this.state

        try {
            await axios[method](path, {
                name,
                summary,
                images,
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({ errors })
            }
        }
    }

    onChange = (path, bool) => {
        let copyImages = []
        if (bool === undefined) {
            this.setState(prevState => ({
                images: [...prevState.images, path]
            }))
        } else {
            copyImages = this.state.images.filter(val =>
                val !== path
            )
            this.setState({
                images: copyImages
            })
        }
    }

    render() {
        const {
            name,
            summary,
            images,
            errors
        } = this.state

        return (
            <div>
                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Газрууд</Typography>
                            <TextField
                                error={!!errors.name}
                                helperText={errors.name}
                                label="Нэр"
                                value={name}
                                onChange={this.handleChange('name')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.summary}
                                helperText={errors.summary}
                                label="Товч тайлбар"
                                value={summary}
                                onChange={this.handleChange('summary')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={4}
                                fullWidth
                            />
                            <Box my={3}>
                                <DropZoneMultiple
                                    uploadPath="img/upload"
                                    onChange={this.onChange}
                                    files={images}
                                    error={!!errors.images}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(AttractForm)
