import React, { Component } from 'react'
import { App } from './../layouts'

export default class NotFound extends Component {
    render() {
        return (
            <App>
                <div>Page not found</div>
            </App>
        )
    }
}
