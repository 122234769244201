import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Button,
    Grid,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    OutlinedInput,
    CircularProgress
} from '@material-ui/core'
import {
    DropZoneMultiple,
    BaseEditor
} from './../components'
import {
    Plus,
    Minus
} from 'mdi-material-ui'
import { CustomSnackBar } from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    marginLeft: {
        margin: '0 10px 0 0'
    },
    marginBottom: {
        margin: '0 0 20px 0'
    },
    marginVerticalSmall: {
        margin: '10px 0 10px 0'
    },
})

class TourForm extends Component {

    static defaultProps = {
        name: '',
        images: [],
        duration: '',
        map: '',
        summary: '',
        code: '',
        tour_guide: '',
        tour_type: '',
        pick_up_service: '',
        activity_level: '',
        highlights: [''],
        included: [''],
        not_included: [''],
        itinerary: '',
        end_point: '',
        destination_ids: [],
        destinations: []
    }

    constructor(props) {
        super(props)

        const {
            name,
            images,
            duration,
            map,
            summary,
            code,
            tour_guide,
            tour_type,
            pick_up_service,
            activity_level,
            highlights,
            included,
            not_included,
            itinerary,
            end_point,
            destination_ids,
            destinations,
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            errors: {},
            name,
            images,
            duration,
            map,
            summary,
            code,
            tour_guide,
            tour_type,
            pick_up_service,
            activity_level,
            highlights,
            included,
            not_included,
            itinerary,
            end_point,
            destination_ids,
            destinations
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            errors: {},
            name: '',
            images: [],
            duration: '',
            map: '',
            summary: '',
            code: '',
            tour_guide: '',
            tour_type: '',
            pick_up_service: '',
            activity_level: '',
            highlights: [''],
            included: [''],
            not_included: [''],
            itinerary: '',
            end_point: '',
            destination_ids: []
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true,
            errors: {}
        })

        const {
            path,
            method
        } = this.props

        const {
            name,
            images,
            duration,
            map,
            summary,
            code,
            tour_guide,
            tour_type,
            pick_up_service,
            activity_level,
            highlights,
            included,
            not_included,
            itinerary,
            end_point,
            destination_ids
        } = this.state

        try {
            await axios[method](path, {
                name,
                images,
                duration,
                map,
                summary,
                code,
                tour_guide,
                tour_type,
                pick_up_service,
                activity_level,
                highlights,
                included,
                not_included,
                itinerary,
                end_point,
                destination_ids
            })

            this.setState({
                isSnackOpen: true
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({ errors })
            }
        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    onChange = (path, bool) => {
        let copyImages = []
        if (bool === undefined) {
            this.setState(prevState => ({
                images: [...prevState.images, path]
            }))
        } else {
            copyImages = this.state.images.filter(val =>
                val !== path
            )
            this.setState({
                images: copyImages
            })
        }
    }

    selectChanged = e => {
        const { value } = e.target

        this.setState({ destination_ids: value })
    }

    addField = stateName => e => {
        this.setState(prevState => ({
            [stateName]: [...prevState[stateName], '']
        }))
    }

    removeField = stateName => e => {
        let copyState = this.state[stateName]
        copyState.pop()
        this.setState({
            [stateName]: copyState
        })
    }

    handleField = index => e => {
        const {
            name,
            value
        } = e.target

        let items = [...this.state[name]]
        items[index] = value

        this.setState({
            [name]: items
        })
    }

    editorChange = name => e => {
        this.setState({
            [name]: e.target.getContent()
        })
    }

    render() {
        const {
            name,
            images,
            duration,
            map,
            summary,
            code,
            tour_guide,
            tour_type,
            pick_up_service,
            activity_level,
            highlights,
            included,
            not_included,
            itinerary,
            end_point,
            destination_ids,
            destinations,
            isSnackOpen,
            errors,
            disabled
        } = this.state

        const {
            message
        } = this.props
        const { 
            marginBottom,
            marginVerticalSmall,
        } = this.props.classes
        return (
            <div>
                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                error={!!errors.name}
                                helperText={errors.name}
                                label="Name"
                                value={name}
                                onChange={this.handleChange('name')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.duration}
                                helperText={errors.duration}
                                label="Duration"
                                value={duration}
                                onChange={this.handleChange('duration')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.map}
                                helperText={errors.map}
                                label="Map Html"
                                value={map}
                                onChange={this.handleChange('map')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <div>
                                <Typography className={marginVerticalSmall} variant="body2">
                                    Summary
                                </Typography>
                                <div className={marginBottom}>
                                    <BaseEditor
                                        value={summary}
                                        onChange={this.editorChange('summary')}
                                    />
                                </div>
                            </div>
                            <div>
                                <Typography variant="body2">
                                    Key details
                                </Typography>
                                <TextField
                                    error={!!errors.code}
                                    helperText={errors.code}
                                    type="number"
                                    label="Code"
                                    value={code}
                                    onChange={this.handleChange('code')}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    error={!!errors.tour_guide}
                                    helperText={errors.tour_guide}
                                    label="Tour Guide"
                                    value={tour_guide}
                                    onChange={this.handleChange('tour_guide')}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    error={!!errors.tour_type}
                                    helperText={errors.tour_type}
                                    label="Tour Type"
                                    value={tour_type}
                                    onChange={this.handleChange('tour_type')}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    error={!!errors.pick_up_service}
                                    helperText={errors.pick_up_service}
                                    label="Pick-up Service"
                                    value={pick_up_service}
                                    onChange={this.handleChange('pick_up_service')}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    error={!!errors.activity_level}
                                    helperText={errors.activity_level}
                                    type="number"
                                    inputProps={{ max: "10" }}
                                    label="Activity Level (1-10)"
                                    value={activity_level}
                                    onChange={this.handleChange('activity_level')}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                            </div>
                            <div>
                                <Typography className={marginVerticalSmall} variant="body2">
                                    Itinerary
                                </Typography>
                                <div className={marginBottom}>
                                    <BaseEditor
                                        value={itinerary}
                                        onChange={this.editorChange('itinerary')}
                                    />
                                </div>
                            </div>
                            <div>
                                <Typography variant="body2">
                                    Highlights
                                </Typography>
                                {
                                    highlights.map((value, index) => {
                                        return (
                                            <TextField
                                                name='highlights'
                                                key={index}
                                                value={value}
                                                onChange={this.handleField(index)}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )
                                    })
                                }
                                <Plus
                                    onClick={this.addField('highlights')}
                                />
                                <Minus
                                    onClick={this.removeField('highlights')}
                                />
                            </div>
                            <div>
                                <Typography variant="body2">
                                    Included
                                </Typography>
                                {
                                    included.map((value, index) => {
                                        return (
                                            <TextField
                                                name='included'
                                                key={index}
                                                value={value}
                                                onChange={this.handleField(index)}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )
                                    })
                                }
                                <Plus
                                    onClick={this.addField('included')}
                                />
                                <Minus
                                    onClick={this.removeField('included')}
                                />
                            </div>
                            <div>
                                <Typography variant="body2">
                                    Not Included
                                </Typography>
                                {
                                    not_included.map((value, index) => {
                                        return (
                                            <TextField
                                                name='not_included'
                                                key={index}
                                                value={value}
                                                onChange={this.handleField(index)}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )
                                    })
                                }
                                <Plus
                                    onClick={this.addField('not_included')}
                                />
                                <Minus
                                    onClick={this.removeField('not_included')}
                                />
                            </div>
                            <TextField
                                error={!!errors.end_point}
                                helperText={errors.end_point}
                                label="End Point"
                                value={end_point}
                                onChange={this.handleChange('end_point')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <Box my={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="destination_ids-simple">Destinations</InputLabel>
                                    <Select
                                        error={!!errors.destination_ids}
                                        helperText={errors.destination_ids}
                                        value={destination_ids}
                                        onChange={this.selectChanged}
                                        multiple
                                        input={
                                            <OutlinedInput title="destination_ids" id="destination_ids-simple" />
                                        }
                                    >
                                        {
                                            destinations.map(val =>
                                                <MenuItem
                                                    key={val.id}
                                                    value={val.id}
                                                >
                                                    {val.name}
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box my={3}>
                                <Typography variant="subtitle1">Images</Typography>
                                <DropZoneMultiple
                                    uploadPath="img/upload"
                                    onChange={this.onChange}
                                    files={images}
                                    error={!!errors.images}
                                />
                            </Box>
                            <Box my={3}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={disabled}
                                >
                                    {
                                        disabled ? <CircularProgress size={25} color="primary"/> : "Хадгалах"
                                    }
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <CustomSnackBar
                        message={message}
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(TourForm)
