import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { Dashboard } from './../layouts'
import {
    TextField,
    Grid,
    Typography,
    CardMedia
} from '@material-ui/core'
import axios from './../plugins/axios'

const styles = theme => ({
    card: {
        height: 0,
        paddingTop: '150%', // 16:9,
    }
})

class ResumeForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Анкет',
            key: shortid.generate(),
            data: {}
        }
    }

    componentWillMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/resumes/${id}`, { progress: true })

            console.log(data)

            this.setState({ data })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            data
        } = this.state

        const { card } = this.props.classes

        console.log(data)

        return (
            <Dashboard title={title}>
                <form>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item xs={3}>
                            <Grid item xs>
                                <CardMedia
                                    className={card}
                                    image={`${process.env.REACT_APP_API_URL}/image/${data.image}`}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Таны сонирхож буй албан тушаал"
                                        value={data.interested_position}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Ажилд орох боломжтой огноо"
                                        value={data.join_date_availability}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Эцгийн /эхийн/ нэр"
                                        value={data.parent_name}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Өөрийн нэр"
                                        value={data.name}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Ургийн овог"
                                        value={data.family_name}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Үндэс угсаа"
                                        value={data.nationality}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Төрсөн он, сар, өдөр"
                                        value={data.birthday}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Хүйс"
                                        value={data.gender}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Төрсөн аймаг, хот"
                                        value={data.birthplace}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Регистр дугаар"
                                        value={data.register}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={3}
                    >

                        <Grid item xs={6}>
                            <TextField
                                label="Оршин суугаа хаяг"
                                value={data.address}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Орон сууцны эзэмшлийн төрөл"
                                value={data.owner_of_building}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Жолооны үнэмлэхтэй эсэх"
                                value={data.driving_license}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Жолооны үнэмлэхний төрөл"
                                value={data.driving_type}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Хувийн машинтай эсэх"
                                value={data.private_car}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Утас (гар, гэр, бусад)"
                                value={data.number}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="И-мэйл хаяг"
                                value={data.email}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Facebook хаяг"
                                value={data.facebook}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Боловсролын байдал</Typography>
                        </Grid>
                    </Grid>
                    {
                        (data.education) && data.education.map(item => {
                            return <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Хаана (Улс,Хот)"
                                        value={item.location}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Сургуулийн нэр"
                                        value={item.school_name}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Он /элссэн, төгссөн/"
                                        value={item.date}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Эзэмшсэн мэргэжил"
                                        value={item.major}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Зэрэг цол"
                                        value={item.grade}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Голч дүн"
                                        value={item.gpa}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        })
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Мэргэшлийн сургалтанд хамрагдсан байдал</Typography>
                        </Grid>
                    </Grid>
                    {
                        (data.courses) && data.courses.map(item => {
                            return <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Хаана ямар байгуулагад"
                                        value={item.where}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Хэзээ"
                                        value={item.when}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Үргэлжилсэн хугацаа"
                                        value={item.how_long}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Ямар чиглэлээр"
                                        value={item.what}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        })
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Нийт ажилласан жил</Typography>
                        </Grid>
                    </Grid>
                    {
                        (data.experiences) && data.experiences.map(item => {
                            return <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Байгууллагын нэр"
                                        value={item.name}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Албан тушаал"
                                        value={item.position}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Огноо /орсон, гарсан/"
                                        value={item.date}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Гарсан шалтгаан"
                                        value={item.reason_to_leave}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Цалин"
                                        value={item.salary}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        })
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                label="Хэрэв та удаан хугацаагаар ажил хийгээгүй бол шалтгаанаа бичнэ үү"
                                value={data.reason_for_unemployeement}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Гадаад хэлний мэдлэг</Typography>
                        </Grid>
                    </Grid>
                    {
                        (data.language_skills) && data.language_skills.map(item => {
                            return <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Гадаад хэлний нэр"
                                        value={item.name}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Бичих чадвар"
                                        value={item.writing}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Өөрөө ярих чадвар"
                                        value={item.speaking}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Сонсох чадвар"
                                        value={item.listening}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Унших чадвар"
                                        value={item.reading}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Орчуулах"
                                        value={item.translating}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        })
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                label="Та хэлний болон бусад мэргэшлийн чиглэлээр ОУ-д хүлээн зөвшөөрөгдсөн шалгалт өгсөн үү?"
                                value={data.any_course_score}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Нэр"
                                value={data.course_name}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Огноо"
                                value={data.course_date}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Таныг сайн тодорхойлох 3 хүн</Typography>
                        </Grid>
                    </Grid>
                    {
                        (data.references) && data.references.map(item => {
                            return <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Овог нэр"
                                        value={item.name}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Эрхэлдэг ажил"
                                        value={item.title}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Таны юу болох"
                                        value={item.relation}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Холбоо барих утас"
                                        value={item.number}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        })
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Гэр бүлийн байдал</Typography>
                        </Grid>
                    </Grid>
                    {
                        (data.families) && data.families.map(item => {
                            return <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Таны юу болох"
                                        value={item.relation}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Овог нэр"
                                        value={item.name}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Төрсөн он"
                                        value={item.birthday}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Одоо эрхэлж буй ажил, албан тушаал"
                                        value={item.current_job}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Холбоо барих утас"
                                        value={item.number}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        })
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Ураг төрлийн байдал</Typography>
                        </Grid>
                    </Grid>
                    {
                        (data.relatives) && data.relatives.map(item => {
                            return <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Таны юу болох"
                                        value={item.relation}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Овог нэр"
                                        value={item.name}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Төрсөн он"
                                        value={item.birthday}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Одоо эрхэлж буй ажил, албан тушаал"
                                        value={item.current_job}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Холбоо барих утас"
                                        value={item.number}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        })
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Бусад</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Та өөрийн эрүүл мэндийн талаар бичнэ үү"
                                value={data.health}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Таны ойрын 5 жилийн зорилго"
                                value={data.goal}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Таны давуу тал"
                                value={data.advantage}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Таны сул тал"
                                value={data.disadvantage}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Өөрийнхөө талаар нэмж танилцуулах боломж"
                                value={data.aditional_info}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Цэргийн алба хаасан эсэх (Зөвхөн эрэгтэй бөглөнө!)"
                                value={data.military_service}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Та хаана алба хаасан бэ"
                                value={data.which_military_service}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Та ажлын байрны талаарх мэдээллийг ямар эх сурвалжаас авсан бэ?"
                                value={data.source}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Таны хүсч буй цалин"
                                value={data.wanted_salary}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </form>
            </Dashboard>
        )
    }
}

export default withStyles(styles)(ResumeForm)
